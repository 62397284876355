<template>
  <div>
    <img class="img22" src="../../../assets/aboutUs/introduction.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "IntroductionPage",
};
</script>

<style>
.img22 {
  width: 900px;
  margin-left: 100px;
}
</style>

<template>
  <div>
    <div class="add">
      <input v-model="newName" placeholder="请输入新用户账号" type="text">
      <input v-model="newPassword" placeholder="请输入新用户密码" type="text">
      <button @click="add">确定添加</button>
      <button @click="theUserList">刷新列表</button>
    </div>
    <div class="list">
      <div v-for="item in userList" :key="item.id" class="item">
        <el-card class="users">
          <p>账号：{{item.username}}</p>
          <el-button @click="del(item.id)" style="float: right; display:inline" type="text">删除</el-button>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserList, addUser, delUser } from '@/api/admin'
export default {
  name: 'usersPage',
  data () {
    return {
      userList: [],
      newName: '',
      newPassword: ''
    }
  },
  created () {
    this.theUserList()
  },
  methods: {
    // 获取用户列表
    async theUserList () {
      const res = await getUserList()
      console.log(res)
      this.userList = res.data.data
      console.log('=========>', this.userList)
    },
    // 添加用户
    async add () {
      const res = await addUser(this.newName, this.newPassword)
      console.log(res)
      this.theUserList()
    },
    // 删除用户
    async del (id) {
      const res = await delUser(id)
      console.log(res)
    }
  }
}
</script>

<style lang="less">
  .add {
    margin: 20px;
    input {
      margin: 5px;
    }
  }
  .users {
    width: 600px;
    margin: 10px;
  }
</style>

import request from '@/utils/request'

// 列表查询用户

export const getUserList = () => {
  return request.get('/admin/user/list')
}

// 新增用户

export const addUser = (username, password) => {
  return request.post('/admin/user/insert', {
    username,
    password
  })
}

// 删除用户

export const delUser = (id) => {
  return request.put('/admin/user/delete/' + id)
}

// 修改密码

export const change = (oldPW, newPW) => {
  return request.put('/admin/employee/updatePassword', {
    oldPW,
    newPW
  })
}

// 新增商品信息

export const addGood = (obj) => {
  return request.post('/admin/goods/insert', obj)
}

// 查询商品列表
export const getGoodList = () => {
  return request.get('/admin/goods/list')
}

// 删除商品

export const delGood = (id) => {
  return request.delete('/admin/goods/' + id)
}

// 新增新闻信息
export const addNews = (obj) => {
  return request.post('/admin/news/insert', obj)
}

// 获取新闻列表
export const getNewsList = () => {
  return request.get('/admin/news/list')
}

// 删除新闻

import request from '@/utils/request'

// 管理员登录
export const adminLogin = (username, password) => {
  return request.post('/admin/employee/login', {
    username,
    password
  })
}

// 用户登录

export const userLogin = (username, password) => {
  return request.post('/user/user/login', {
    username,
    password
  })
}

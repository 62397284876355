<template>
  <div class="layout-home">
    <div class="news">
      <el-card class="industry">
        <div slot="header" class="clearfix">
          <span>行业动态</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            @click="toNews()"
            >了解更多</el-button
          >
        </div>
        <div class="text item">
          <el-card class="news-item">
            <img
              src="../../../assets/理事会现场_20240328170101A297.jpeg"
              alt=""
            />
            <span>王波当选中国拍卖行业协会会长</span>
            <p>
              3月28日上午，中国拍卖行业协会第六届五次理事会暨第六届九次常务理事会在北京召开。中国物流与采购联合会......
            </p>
            <time datetime="">2024-03-28</time>
          </el-card>
          <el-card class="news-item">
            <img
              src="../../../assets/理事会现场_20240328170101A297.jpeg"
              alt=""
            />
            <span>【嘉德香港•春拍】苏富比伦敦拍卖征集</span>
            <p>
              九十翁黄永玉百呎巨制《雨后新荷》，汪洋恣肆，极为罕见，可谓是市场迄今所见黄永玉彩墨作品最大者...
            </p>
            <time datetime="">2024-03-28</time>
          </el-card>
        </div>
      </el-card>
      <el-card class="usNews">
        <div slot="header" class="clearfix">
          <span>迎顺新闻</span>
          <el-button style="float: right; padding: 3px 0" type="text"
            >了解更多</el-button
          >
        </div>
        <div class="text item">
          <el-card class="us-news-item">
            <img src="../../../assets/us-news.jpg" alt="" />
            <span>现当代艺术专场 2024北京拍卖征集</span>
            <p>
              四时更迭，春景熙熙，佳期如许。北京保利拍卖现当代艺术部倾情推出全新第十三季线上拍卖，邀您一同徜徉艺术花海....
            </p>
            <time datetime="">2024-03-28</time>
          </el-card>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "newPagesIndex",
};
</script>

<style>
.layout-home {
  background-color: #fff;
  border: 5px solid #b2cbeb;
  border-top: 0;
  border-bottom: 0;
  width: 1200px;
  margin: 0px auto;
  padding-top: 30px;
}
</style>

<template>
  <div class="banner">
    <div class="carousel">
      <el-carousel height="400px">
        <el-carousel-item>
          <img src="../../assets/轮播1.jpg" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../assets/轮播2.jpg" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="../../assets/轮播3.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 帮助中心卡片 -->
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>帮助中心</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="toHelp"
          >查看更多</el-button
        >
      </div>
      <div class="item">业务法规</div>
      <div class="item">流程介绍</div>
      <div class="item">处理中心</div>
    </el-card>
    <div class="mid-border"></div>
    <!-- 流程图 -->
    <div class="flowChart">
      <img src="../../assets/flowChart.png" alt="" />
    </div>
    <div class="mid-border"></div>
    <div class="auction" v-if="isLogin">
      <h1>拍卖动态</h1>
      <el-card class="auction-items">
        <el-card
          @click="toList()"
          v-for="(item, index) in goodList.slice(0, 4)"
          :key="index"
          class="theItem"
        >
          <img :src="item.photo" alt="" class="theImg" />
        </el-card>
      </el-card>
      <div class="mid-border"></div>
    </div>
    <div class="news">
      <h1>新闻动态</h1>
      <el-card class="industry">
        <div slot="header" class="clearfix">
          <span>行业动态</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            @click="toNews()"
            >了解更多</el-button
          >
        </div>
        <div class="text item">
          <el-card class="news-item">
            <img src="../../assets/理事会现场_20240328170101A297.jpeg" alt="" />
            <span>王波当选中国拍卖行业协会会长</span>
            <p>
              3月28日上午，中国拍卖行业协会第六届五次理事会暨第六届九次常务理事会在北京召开。中国物流与采购联合会......
            </p>
            <time datetime="">2024-03-28</time>
          </el-card>
          <el-card class="news-item">
            <img src="../../assets/理事会现场_20240328170101A297.jpeg" alt="" />
            <span>【嘉德香港•春拍】苏富比伦敦拍卖征集</span>
            <p>
              九十翁黄永玉百呎巨制《雨后新荷》，汪洋恣肆，极为罕见，可谓是市场迄今所见黄永玉彩墨作品最大者...
            </p>
            <time datetime="">2024-03-28</time>
          </el-card>
        </div>
      </el-card>
      <el-card class="usNews">
        <div slot="header" class="clearfix">
          <span>迎顺新闻</span>
          <el-button style="float: right; padding: 3px 0" type="text"
            >了解更多</el-button
          >
        </div>
        <div class="text item">
          <el-card class="us-news-item">
            <img src="../../assets/us-news.jpg" alt="" />
            <span>现当代艺术专场 2024北京拍卖征集</span>
            <p>
              四时更迭，春景熙熙，佳期如许。北京保利拍卖现当代艺术部倾情推出全新第十三季线上拍卖，邀您一同徜徉艺术花海....
            </p>
            <time datetime="">2024-03-28</time>
          </el-card>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { getGoodList } from "@/api/admin";

export default {
  name: "LayoutHome",
  data() {
    return {
      isLogin: false,
      goodList: [],
    };
  },
  created() {
    if (
      localStorage.getItem("paimai_info") !== null &&
      localStorage.getItem("paimai_info").token !== ""
    ) {
      this.isLogin = true;
      console.log(this.isLogin);
      this.getList();
    }
  },
  methods: {
    toNews() {
      // this.$router.push(path:'')
    },
    toHelp() {
      this.$router.push("/help");
    },
    toList() {
      this.$router.push("/list");
    },
    async getList() {
      const res = await getGoodList();
      console.log(res);
      this.goodList = res.data.data;
      console.log("=====>", this.goodList);
    },
  },
};
</script>

<style lang="less">
.auction {
  h1 {
    font-size: 20px;
    margin: 10px;
  }
  .auction-items {
    width: 98%;
    height: 300px;
    margin: 10px auto;
    .theItem {
      display: inline-block;
      width: 23%;
      height: 240px;
      margin: 10px;
      .theImg {
        width: 100%;
        height: 200px;
      }
    }
  }
}

.banner {
  background-color: #fff;
  margin: 0 auto;
  width: 1200px;
  border: 5px solid #b2cbeb;
  border-top: 0;
  border-bottom: 0;
}
.carousel {
  width: 56%;
  height: 20%;
  margin: 20px;
  display: inline-block;
  .el-carousel__item {
    img {
      height: 100%;
      width: 100%;
    }
  }
}
.box-card {
  height: 400px;
  width: 40%;
  display: inline-block;
}
.flowChart {
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 155px;
  img {
    width: 900px;
  }
}
.item {
  border: 10px;
  margin: 10px;
}
.mid-border {
  background-color: #45689c;
  height: 5px;
  width: 99%;
  margin: 0 auto;
}
.news {
  margin-bottom: 20px;
  h1 {
    margin: 10px 8px;
    font-size: 20px;
  }
  .industry {
    width: 45%;
    display: inline-block;
    min-height: 500px;
    margin-left: 10px;
    .news-item {
      position: relative;
      margin-bottom: 20px;
      img {
        height: 130px;
        width: 130px;
      }
      span {
        position: absolute;
        top: 18px;
        left: 160px;
        font-size: 18px;
      }
      p {
        position: absolute;
        top: 48px;
        left: 160px;
        width: 300px;
        font-size: 13px;
        color: gray;
      }
      time {
        position: absolute;
        top: 138px;
        left: 160px;
        font-size: 14px;
        color: gray;
      }
    }
  }
  .usNews {
    width: 52%;
    display: inline-block;
    margin-left: 10px;
    min-height: 500px;
    .us-news-item {
      padding: 10px;
      position: relative;
      height: 350px;
      img {
        height: 200px;
        width: 400px;
      }
      span {
        position: absolute;
        top: 238px;
        left: 30px;
        font-size: 18px;
      }
      p {
        position: absolute;
        top: 278px;
        left: 30px;
        width: 300px;
        font-size: 13px;
        color: gray;
      }
      time {
        position: absolute;
        top: 338px;
        left: 30px;
        font-size: 14px;
        color: gray;
      }
    }
  }
}
</style>

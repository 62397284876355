<template>
  <div class="aboutBusiness">
    <img src="../../../assets/aboutUs/Business.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "aboutBusinessPage",
};
</script>

<style>
.aboutBusiness {
  img {
    width: 850px;
    margin-left: 130px;
  }
}
</style>

import request from "@/utils/request";

//竞拍出价

export const iwant = (userId,userName,goodsId,price) => {
  return request.post("/user/record/insert", {
    userId:userId,
    userName:userName,
    goodsId:goodsId,
    price:price
  })
}
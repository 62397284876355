<template>
  <div class="activites-container">
    <header class="top-header">
      <div class="top-info">
        <div class="welcome">
          <p>欢迎来到迎顺拍卖</p>
          <ul>
            <li class="user-center">
              <router-link :to="isLogin ? '/user' : '/login'">
                <a href="">
                  <i class="el-icon-user"></i>
                  用户登录
                </a>
              </router-link>
            </li>
            <li class="msg">
              <a href="">
                <i class="el-icon-bell"></i>
                消息中心
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="bgwhite">
        <div class="logoImg">
          <img class="logo" src="../../assets/logo.png" alt="" />
          <img class="slogan" src="../../assets/font.png" alt="" />
        </div>
      </div>
    </header>
    <section class="MenuRouter">
      <!-- 菜单导航栏 -->
      <div class="menu">
        <el-menu
          :router="true"
          background-color="#45689c"
          active-text-color="#ec843c"
          text-color="#fffffe"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="home">首页</el-menu-item>
          <el-menu-item index="list">拍卖会</el-menu-item>
          <el-menu-item index="aboutUs">迎顺简介</el-menu-item>
          <el-menu-item index="news">新闻动态</el-menu-item>
          <el-menu-item index="help">帮助中心</el-menu-item>
        </el-menu>
      </div>
      <!-- 路由出口 -->
      <router-view></router-view>
    </section>
    <!-- 页脚信息 -->
    <footer>
      <div class="ftrInfo">
        <div class="theICP">
          <div>©2024 迎顺(青岛)拍卖有限公司 鲁ICP备2024081616号-1</div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "layoutIndex",
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      activeIndex3: "1",
      isLogin: false,
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style lang="less">
.top-info {
  width: 100%;
  height: 30px;
  background-color: rgb(240, 239, 236);
  border-bottom: 1px solid rgb(209, 201, 201);
  .welcome {
    width: 1200px;
    margin: 0 auto;
    p {
      display: inline;
      line-height: 30px;
      margin-left: 40px;
      font-size: 15px;
      color: rgb(61, 61, 61);
    }
    ul {
      float: right;
      line-height: 30px;
      margin-right: 40px;
      .user-center {
        display: inline;
        margin-right: 5px;
        a {
          text-decoration: none;
          color: rgb(61, 61, 61);
        }
        a:hover {
          color: red;
          text-decoration: underline;
        }
      }
      .msg {
        display: inline;
        a {
          text-decoration: none;
          color: rgb(61, 61, 61);
        }
        a:hover {
          color: red;
          text-decoration: underline;
        }
      }
    }
  }
}
.bgwhite {
  width: 100%;
  background: #fff;
}
.logoImg {
  background-color: #fff;
  border-top: 0;
  border-bottom: 0;
  width: 1200px;
  margin: 0 auto;
  border-bottom: 1px solid rgb(209, 201, 201);
  height: 100px;
  .logo {
    height: 70px;
    margin-left: 40px;
    margin-top: 20px;
    display: inline;
  }
  .slogan {
    float: right;
    margin-top: 20px;
    transform: rotateX(5deg) rotateY(5deg) rotateZ(5deg);
  }
}
.MenuRouter {
  .menu {
    background-color: #45689c;
    .el-menu {
      width: 1200px;
      margin: 0 auto;
    }
  }
}

footer {
  margin-top: -25px;
  background-color: rgb(47, 45, 45);
  .ftrInfo {
    height: 130px;
    width: 1200px;
    margin: 0 auto;
    border-top: 1px solid rgb(209, 201, 201);
    .about {
      margin: 20px;
      width: 70px;
      line-height: 30px;
    }
  }
}
.theICP {
  width: 500px;
  margin-left: 20px;
  line-height: 100px;
  div {
    color: white;
    font-size: 13px;
  }
}
</style>

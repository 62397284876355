<template>
  <div class="main">
    <el-menu
      :router="true"
      class="leftMenu"
      mode="vertical"
      @select="handleSelect"
    >
      <el-menu-item index="/theIntroduction">经营范围</el-menu-item>
      <el-menu-item index="/aboutBusiness">主营业务</el-menu-item>
      <el-menu-item index="/aboutCompany">公司优势</el-menu-item>
    </el-menu>
    <!-- 路由出口 -->
    <div class="innerRouter">
      <router-view> </router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutUsPage",
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style lang="less">
.main {
  background-color: #fff;
  border: 5px solid #b2cbeb;
  border-top: 0;
  border-bottom: 0;
  height: 500px;
  position: relative;
  margin: 0 auto;
  width: 1200px;
  .innerRouter {
    position: absolute;
    left: 110px;
    top: 10px;
    height: 430px;
    width: 1080px;
  }
  .leftMenu {
    width: 100px;
    height: 450px;
  }
}
</style>

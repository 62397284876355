<template>
  <div>
    <div class="change">
      <el-input placeholder="请输入原密码" v-model="oldPass" show-password></el-input><br>
      <el-input placeholder="请输入新密码" v-model="newPass" show-password></el-input><br>
      <el-input placeholder="请确认新密码" v-model="theNew" show-password></el-input><br>
      <el-button type="primary" @click="changePw()">确定</el-button>
    </div>
  </div>
</template>

<script>
import { change } from '@/api/admin'
export default {
  name: 'settingPage',
  data () {
    return {
      oldPass: '',
      newPass: '',
      theNew: ''
    }
  },
  methods: {
    // 修改密码
    async changePw () {
      if (this.newPass === this.theNew) {
        const res = await change(this.oldPass, this.newPass)
        console.log(res)
        if (res.code === 200) {
          alert('密码修改成功')
        }
      } else {
        alert('请保证确认密码与新密码相同')
      }
    }
  }
}
</script>

<style lang="less">
 .change{
  .el-input{
    width: 400px;
    margin: 20px 20px 0 20px;
  }
  .el-button{
    width: 300px;
    margin: 20px;
  }
 }
</style>

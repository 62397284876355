<template>
  <div class="list">
    <div class="select">
      <div>
        类型：
        <el-button type="text">不限</el-button>
        <el-button type="text">债权资产</el-button>
        <el-button type="text">财产权利</el-button>
        <el-button type="text">农副产品</el-button>
        <el-button type="text">酒</el-button>
        <el-button type="text">房产</el-button>
        <el-button type="text">交通工具</el-button>
        <el-button type="text">土地</el-button>
      </div>
      <div>
        地区：
        <div class="area" v-for="item in areaList" :key="item.index">
          <el-button type="text">{{ item.name }}</el-button>
        </div>
      </div>
    </div>
    <div v-if="!islogin" class="nologin">
      <el-empty description="请您登录"></el-empty>
    </div>
    <div v-if="goodList.length == 0">
      <el-empty description="暂无"></el-empty>
    </div>
    <div v-else>
      <div
        v-for="item in goodList"
        :key="item"
        class="goodsCard"
        @click="toDetail(item.id)"
      >
        <el-card shadow="hover">
          <img :src="item.photo" alt="" />
          <div class="detail">
            <h1>{{ item.name }}</h1>
            <div class="time">{{ item.startTime }} — {{ item.endTime }}</div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoodList } from "@/api/admin";

export default {
  name: "layoutList",
  data() {
    return {
      islogin: false,
      goodList: [],
      chooseId: "",
      areaList: [
        { choose: false, name: "北京" },
        { choose: false, name: "山东" },
        { choose: false, name: "天津" },
        { choose: false, name: "河北" },
        { choose: false, name: "山西" },
        { choose: false, name: "辽宁" },
        { choose: false, name: "吉林" },
        { choose: false, name: "黑龙江" },
        { choose: false, name: "上海" },
        { choose: false, name: "江苏" },
        { choose: false, name: "浙江" },
        { choose: false, name: "安徽" },
        { choose: false, name: "福建" },
        { choose: false, name: "江西" },
        { choose: false, name: "河南" },
        { choose: false, name: "湖北" },
        { choose: false, name: "湖南" },
        { choose: false, name: "广东" },
        { choose: false, name: "广西" },
        { choose: false, name: "海南" },
        { choose: false, name: "重庆" },
        { choose: false, name: "四川" },
        { choose: false, name: "贵州" },
        { choose: false, name: "云南" },
        { choose: false, name: "西藏" },
        { choose: false, name: "陕西" },
        { choose: false, name: "甘肃" },
      ],
    };
  },
  created() {
    if (
      localStorage.getItem("paimai_info") !== null &&
      localStorage.getItem("paimai_info").token !== ""
    ) {
      this.islogin = true;
      this.getList();
    }
  },
  methods: {
    async getList() {
      const res = await getGoodList();
      console.log(res);
      this.goodList = res.data.data;
      console.log("=====>", this.goodList);
    },
    toDetail(id) {
      // 向detail页面传id值
      this.$router.push({
        path: "/detail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="less">
.list {
  background-color: #fff;
  border: 5px solid #b2cbeb;
  border-top: 0;
  border-bottom: 0;
  width: 1200px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 200px;
}
.goodsCard {
  height: 400px;
  width: 290px;
  display: inline-block;
  position: relative;
  margin: 10px 0 0 10px;
  img {
    height: 290px;
    width: 100%;
  }
  .detail {
    height: 50px;
    h1 {
      font-size: 24px;
      margin: 5px;
    }
    .time {
      margin: 5px;
      color: grey;
    }
  }
}
.nologin {
  margin-bottom: 200px;
}
.select {
  width: 1160px;
  margin: 0 auto;
  height: 80px;
  font-size: 13px;
  border: 2px solid rgba(68, 77, 120, 0.76);
  border-radius: 5px;
  padding: 10px;
  .area {
    display: inline;
    .el-button {
      margin-right: 10px;
    }
  }
}
</style>

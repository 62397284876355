<template>
  <div>
    <div class="background">
      <img src="../../assets/背景.jpg" width="100%" height="100%" alt="" />
    </div>
    <div>
      <!-- 品牌logo -->
      <div class="topLogo"></div>
      <!-- 登录 -->
      <div class="loginBlock">
        <div class="theLogin">
          <h1>登录</h1>
        </div>
        <div class="loginInput">
          <div class="phoneNum">
            <el-input placeholder="请输入手机号码" v-model="phone" clearable>
            </el-input>
          </div>
          <div class="thePassword">
            <el-input
              placeholder="请输入密码"
              v-model="password"
              show-password
            ></el-input>
          </div>
          <div class="button">
            <el-button type="primary" @click="login">登录</el-button>
          </div>
        </div>
        <!-- <div class="agree">
          <el-checkbox v-model="checked">阅读并同意<a href="">用户协议</a>和<a href="">隐私条款</a></el-checkbox>
        </div> -->
        <div class="others">
          <el-link type="primary" href="http://1.92.158.224:10101/"
            >管理登录</el-link
          >
          <p></p>
          <el-link type="primary">忘记密码</el-link>
        </div>
      </div>
      <!-- ICP备案等 -->
    </div>
  </div>
</template>

<script>
import { adminLogin, userLogin } from "@/api/login";

export default {
  name: "loginPage",
  data() {
    return {
      imgSrc: "",
      phone: "",
      password: "",
    };
  },
  methods: {
    async toAdmin() {
      const res = await adminLogin(this.phone, this.password);
      console.log(res);
      if (res.data.code === 1) {
        this.$router.push({ path: "/admin" });
        this.$store.commit("user/setUserInfo", res.data);
      }
    },
    async login() {
      const res = await userLogin(this.phone, this.password);
      console.log(res);
      if (res.data.code === 1) {
        this.$router.push({ path: "/home" });
        this.$store.commit("user/setUserInfo", res.data);
      }
    },
  },
};
</script>

<style lang="less">
.background {
  width: 100%;
  height: 100%; /**宽高100%是为了图片铺满屏幕 */
  z-index: -1;
  position: absolute;
  background-color: rgb(237, 241, 241);
}
.topLogo {
  margin: 0 auto;
  height: 150px;
  width: 300px;
}
.loginBlock {
  margin: 0 auto;
  background-color: #ffffff;
  height: 400px;
  width: 500px;
  .theLogin {
    height: 100px;
    h1 {
      text-align: center;
      line-height: 100px;
      font-size: 30px;
      color: rgb(75, 73, 73);
    }
  }
  .loginInput {
    height: 160px;
    width: 400px;
    margin: 0 auto;
    .phoneNum {
      margin: 10px;
    }
    .thePassword {
      margin: 10px;
    }
  }
  .button {
    background-color: gold;
    position: relative;
    width: 380px;
    margin-left: 10px;
    .el-button {
      width: 100%;
      font-size: 20px;
    }
  }
  .agree {
    width: 260px;
    margin: 0 auto;
  }
  .others {
    width: 375px;
    margin: 20px auto;
    p {
      display: inline-block;
      width: 260px;
    }
  }
}
</style>

<template>
  <div class="theDetail">
    <el-card>
      <div class="the-img">
        <img :src="detail.photo" alt="" />
      </div>
      <div class="content">
        <div class="the-title">{{ detail.name }}</div>
        <div class="the-client">委托公司：{{ detail.client }}</div>
        <div class="begin">开始时间：{{ detail.startTime }}</div>
        <div class="end">结束时间：{{ detail.endTime }}</div>
        <div class="remark">{{ detail.remark }}</div>
      </div>
      <el-card
        shadow="hover"
        style="width: 400px; height: 80px"
        class="endCard"
      >
        <div style="width: 100%; display: inline-block">
          <el-statistic
            format="DD天HH小时mm分钟"
            :value="new Date(detail.endTime)"
            time-indices
            title="🚩距离拍卖会结束还有："
          >
          </el-statistic>
        </div>
      </el-card>
      <el-card
        v-model="activeName1"
        class="auctioneer"
        @tab-click="handleClick"
      >
        <el-tabs>
          <el-tab-pane label="拍卖师信息" name="f">{{
            detail.auctioneer
          }}</el-tab-pane>
          <el-tab-pane label="拍卖师发言" name="s">{{
            detail.speak
          }}</el-tab-pane>
        </el-tabs>
      </el-card>
      <!-- <a class="download-file" href="" @click="downloadFile">下载资料</a> -->
      <el-button
        type="primary"
        circle
        class="download-file"
        @click="downloadFile"
        >下 载<br />资 料</el-button
      >
      <el-button type="primary" class="baomin" v-if="!isOver">报名</el-button>
      <el-button type="primary" class="baomin" v-else>拍卖会已结束</el-button>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="重要信息" name="first">
          <img class="box-img" :src="detail.iminfo" alt="" />
        </el-tab-pane>
        <el-tab-pane label="拍卖公告" name="second">
          <img class="box-img" :src="detail.notice" alt="" />
        </el-tab-pane>
        <el-tab-pane label="竞拍须知" name="third">
          <img class="box-img" :src="detail.known" alt="" />
        </el-tab-pane>
        <el-tab-pane label="标的介绍" name="fourth">
          <img class="box-img" :src="detail.introduction" alt="" />
        </el-tab-pane>
        <el-tab-pane label="我要出价" name="five" class="my-price">
          <el-input :rows="2" placeholder="请输入您的出价" v-model="toPrice">
          </el-input>
          <el-button type="primary" class="iwant-btn" @click="iWantTo"
            >出价</el-button
          >
          <h4>竞价记录</h4>
          <el-table
            :data="detail.record"
            height="790"
            border
            style="width: 100%"
          >
            <el-table-column prop="userName" label="用户" width="180">
            </el-table-column>
            <el-table-column prop="price" label="出价"> </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import { getGoodList } from "@/api/admin";
import { iwant } from "@/api/user";

export default {
  name: "detailPage",
  data() {
    return {
      id: 0,
      list: [],
      detail: {},
      activeName: "first",
      activeName1: "f",
      isOver: false,
      toPrice: "",
    };
  },
  created() {
    // 一进页面就接收id
    console.log(this.$route.query.id);
    this.id = this.$route.query.id;
    console.log("idididid====>", this.id);
    // 获取拍卖会列表
    this.getList();
  },
  methods: {
    // 获取拍卖会列表
    async getList() {
      const res = await getGoodList();
      console.log(res);
      // 将列表赋值给list
      this.list = res.data.data;
      console.log("=====>", this.list);
      this.getDetail();
    },
    // 根据id遍历list获取详情
    getDetail() {
      console.log("id", this.id);
      this.list.forEach((item) => {
        if (item.id - 1 === this.id - 1) {
          this.detail = item;
          console.log("item", item.endTime);
          console.log("========-->", this.detail);
          if (Date.now() > new Date(item.endTime)) {
            this.isOver = true;
          }
        }
      });
      if (this.detail.record) {
        this.newRecord = this.detail.record;
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    downloadFile() {
      window.open(this.detail.file);
    },

    async iWantTo() {
      // 获取用户信息
      const userInfoStr = localStorage.getItem("paimai_info");
      const userInfo = JSON.parse(userInfoStr);
      console.log(userInfo);
      //字符串转int
      const res = await iwant(
        userInfo.data.id,
        userInfo.data.userName,
        parseInt(this.id),
        this.toPrice
      );
      if (res.data.code === 1) {
        console.log("出价成功");
        // 刷新页面
        this.getList();
      }
    },
  },
};
</script>

<style lang="less">
.theDetail {
  background-color: #fff;
  border: 5px solid #b2cbeb;
  border-top: 0;
  border-bottom: 0;
  width: 1200px;
  height: 1400px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 50px;
  .el-card {
    margin: 30px;
    height: 1350px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .the-img {
    margin: 30px;
    border: 3px solid #ccc;
    width: 300px;
    display: inline-block;
    img {
      height: 300px;
      width: 300px;
    }
  }
  .endCard {
    background-color: #94bce6a0;
    position: absolute;
    top: 245px;
    left: 395px;
  }
  .auctioneer {
    position: absolute;
    width: 270px;
    height: 200px;
    top: 20px;
    right: 50px;
  }
  .baomin {
    position: absolute;
    top: 275px;
    right: 80px;
    height: 80px;
    width: 270px;
  }
  .download-file {
    position: absolute;
    top: -10px;
    right: 10px;
    height: 60px;
    width: 60px;
  }
  .content {
    width: 600px;
    height: 300px;
    display: inline-block;
    position: relative;
    .the-title {
      position: absolute;
      font-size: 30px;
    }
    .the-client {
      position: absolute;
      top: 60px;
    }
    .begin {
      top: 90px;
      position: absolute;
      color: #787474;
    }
    .end {
      top: 90px;
      left: 200px;
      position: absolute;
      color: #787474;
    }
    .remark {
      width: 430px;
      top: 120px;
      position: absolute;
      font-size: 12px;
    }
  }
  .box-img {
    width: 100%;
  }
}
.my-price {
  h4 {
    margin: 20px;
  }
  .iwant-btn {
    float: right;
    margin-top: 10px;
  }
}
</style>

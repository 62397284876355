<template>
  <div class="main11">
    <img src="../../../assets/help/gov.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "aboutPage",
};
</script>

<style lang="less">
.main11 {
  height: 300px;
  img {
    margin: 20px;
    width: 80%;
  }
}
</style>

<template>
    <div class="index">
        <el-container direction="vertical">
            <el-header style="height:80px">
                <span>迎顺拍卖后台管理系统</span>
            </el-header>
            <el-container>
                <el-aside style="width: 140px;">
                    <el-menu :router="true" class="leftMenu" mode="vertical" @select="handleSelect">
                        <el-menu-item index="/users">用户管理</el-menu-item>
                        <el-menu-item index="/activites">拍卖会管理</el-menu-item>
                        <el-menu-item index="/setting">设置</el-menu-item>
                      </el-menu>
                </el-aside>
                <el-container>
                    <el-main style="width:100%">
                        <div class="innerRouter">
                            <router-view></router-view>
                        </div>
                    </el-main>
                </el-container>
            </el-container>
        </el-container>
    </div>
</template>

<script>
export default {
  name: 'adminIndex'
}
</script>

<style lang="less">
.index{
    height: calc(100vh);
}
el-aside{
    border-right:1px solid #DCDFE6;
}
el-header{
    border-bottom:1px solid #DCDFE6;
    background-color: #45689c;
    span {
        line-height: 80px;
        margin: 40px;
        font-size: 30px;
        color: aliceblue;
    }
}
html, body, #app, .el-container {
    height: 100%;
}
.innerRouter{
    position:absolute;

    left: 150px;
    top:90px;
    height: 630px;
    width: 1260px;
}
</style>

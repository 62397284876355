<template>
  <div>user</div>
</template>

<script>
export default {
  name: 'userPage'
}
</script>

<style>

</style>

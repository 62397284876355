<template>
  <div class="main12">
    <img src="../../../assets/aboutUs/aboutCompany.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "aboutCompanyPage",
};
</script>

<style lang="less">
.main12 {
  img {
    width: 850px;
    margin-left: 10%;
  }
}
</style>

<template>
  <div>
    <div class="add">
      <el-input placeholder="请输入拍卖会名称" v-model="paiName"></el-input><br>
      <el-input placeholder="请输入委托单位" v-model="clientName"></el-input><br>
      <el-input class="area" placeholder="请输入拍卖会简介" v-model="remark" type="textarea"></el-input><br>
      <el-date-picker
      v-model="beginTime"
      type="date"
      value-format="yyyy-MM-dd"
      placeholder="选择开始日期">
      </el-date-picker><br>
      <el-date-picker
      v-model="endTime"
      type="date"
      value-format="yyyy-MM-dd"
      placeholder="选择结束日期">
      </el-date-picker>

      <el-upload
        class="upload-demo"
        ref="upload"
        action="http://1.92.158.224:8085/admin/common/upload"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :file-list="fileList"
        :limit="1"
        :on-success="(response)=>{return onSuccess(response)}"
        :auto-upload="true">
      <el-button slot="trigger" size="small" type="primary">选取拍卖会图片</el-button>
      <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传服务器</el-button>
      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
      </el-upload>
    </div>
    <div class="list">
      <div v-for="item in list" :key="item.id" class="item">
        <el-card class="acivites">
          <p>名称:{{item.name}}</p>
          <el-button @click="del(item.id)" style="float: right; display:inline" type="text">删除</el-button>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { addGood, getGoodList, delGood } from '@/api/admin'

export default {
  name: 'activitePage',
  data () {
    return {
      paiName: '',
      clientName: '',
      beginTime: '',
      endTime: '',
      photoSrc: '',
      remark: '',
      list: []
    }
  },
  created () {
    this.theList()
  },
  methods: {
    onSuccess (response) {
      console.log('这是response', response)
      this.photoSrc = response.data
    },
    async submitUpload () {
      console.log('------------------------------->', this.fileList)
      const obj = {
        name: this.paiName,
        photo: this.photoSrc,
        client: this.clientName,
        startTime: this.beginTime,
        endTime: this.endTime,
        remark: this.remark
      }
      console.log(obj)
      const res = await addGood(obj)
      console.log(res)
      this.theList()
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    async theList () {
      const res = await getGoodList()
      console.log(res)
      this.list = res.data.data
    },
    async del (id) {
      const res = await delGood(id)
      // 刷新当前页面
      this.theList()
      console.log(res)
    }
  }
}
</script>

<style lang="less">
  .add{
    .el-input{
      width: 400px;
      margin: 10px;
      margin-top: 0;
    }
    .area{
      max-width: 400px;
      margin: 10px;
      margin-top: 0;
      margin-left: 15px;
    }
  }
  .upload-demo{
    margin-left: 25px;
  }
</style>

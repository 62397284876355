<template>
  <div class="main1">
    <el-empty description="尽请期待"></el-empty>
  </div>
</template>

<script>
export default {
  name: "introducePage",
};
</script>

<style lang="less"></style>

import Vue from 'vue'
import {
  Button,
  Menu,
  MenuItem,
  Carousel,
  CarouselItem,
  Card,
  Skeleton,
  Container,
  Input,
  Checkbox,
  Link,
  Statistic,
  Upload,
  Tabs,
  TabPane,
  Empty,
  DatePicker,
  Form,
  FormItem,
  Table,
  TableColumn, 
} from 'element-ui'

Vue.use(Button)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Card)
Vue.use(Skeleton)
Vue.use(Container)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Link)
Vue.use(Statistic)
Vue.use(Upload)
Vue.use(Empty)
Vue.use(DatePicker)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Table);
Vue.use(TableColumn);
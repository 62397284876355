import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/index'
import Login from '@/views/login/login'
import User from '@/views/user/user'

import Home from '@/views/layout/home'
import Help from '@/views/layout/helpPage/help'
import List from '@/views/layout/list'
import Detail from '@/views/layout/detail'
import About from '@/views/layout/helpPage/about'
import Introduce from '@/views/layout/helpPage/introduce.vue'
import Support from '@/views/layout/helpPage/support.vue'

import Admin from '@/views/admin/index'
import Users from '@/views/admin/users'
import Activites from '@/views/admin/activites'
import Setting from '@/views/admin/setting'

import AboutUs from '@/views/layout/aboutUs/aboutUs'
import AboutCompany from '@/views/layout/aboutUs/aboutCompany'
import AboutBusiness from '@/views/layout/aboutUs/aboutBusiness'
import TheIntroduction from '@/views/layout/aboutUs/theIntroduction'

import News from '@/views/layout/newsPage/index'

Vue.use(VueRouter)

const routes = [
  { path: '/login', component: Login },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      { path: '/home', component: Home },
      {
        path: '/help',
        redirect: '/about',
        component: Help,
        children: [
          { path: '/about', component: About },
          { path: '/introduce', component: Introduce },
          { path: '/support', component: Support }
        ]
      },
      {
        path: '/aboutUs',
        redirect: 'theIntroduction',
        component: AboutUs,
        children: [
          { path: '/aboutCompany', component: AboutCompany },
          { path: '/theIntroduction', component: TheIntroduction },
          { path: '/aboutBusiness', component: AboutBusiness }
        ]
      },
      { path: '/news', component: News },
      { path: '/list', component: List },
      { path: '/detail', component: Detail }
    ]
  },
  {
    path: '/admin',
    component: Admin,
    children: [
      { path: '/users', component: Users },
      { path: '/activites', component: Activites },
      { path: '/setting', component: Setting }
    ]
  },
  { path: '/user', component: User }
]

const router = new VueRouter({
  routes
})

export default router

<template>
  <div>
    <el-empty description="尽请期待"></el-empty>
  </div>
</template>

<script>
export default {
  name: 'supportPage'
}
</script>

<style>

</style>
